import { Layout } from "layouts";
import i18n from "@/i18n/config";
// Interfaces
import { IBlogPostData } from "@/i18n/json/blog/interfaces";
// Blog data
import { blogData } from "@/i18n/json/blog";
// Page Specific components
import {
  VideoHeader,
  ProductNavigation,
  FeaturedLogos,
  WhatWeDo,
  OurProducts,
  ShippingTrainingSupport,
  OurClients,
  ProjectAndPartners,
  CustomerReviews,
  CustomerVideos,
  BoseVentures,
  LatestBlogs,
  PublishedResearch,
  GetInTouch,
} from "@/components/home";
// Reusable components
import LatestBlogCarousel from "@/components/blog/latest-blog-carousel";
// Lazy load
import { LazyLoadComponent } from "react-lazy-load-image-component";
// Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FastestGrowingCompany } from "@/components/home/text-banners";
import BoseBanner from "@/components/home/bose-banner/bose-banner";
import { SlidesHero } from "../components/home/slides-hero/SlidesHero";
import BlackFridayPromoPopup from "@/components/black-friday-promo-popup/black-friday-promo-popup";
import Head from "next/head";

const Index: React.FC = (props) => {
  const { blogPostData } = props as { blogPostData: IBlogPostData | null };
  const enBlog = blogPostData && blogPostData.en;
  const esBlog = blogPostData && blogPostData.es;
  const latestBlogData = blogPostData && blogPostData.data.latest;

  if (blogPostData) {
    i18n.changeLanguage("en").then();
    i18n.addResourceBundle("en", "translation", { blog: enBlog }, true, false);
    i18n.addResourceBundle("es", "translation", { blog: esBlog }, true, false);
  }

  return (
    <Layout page="index">
      <Head>
        <link
          rel="preload"
          href="/assets/images/black-friday-promo-popup-background.svg"
          as="image"
        />
      </Head>
      <SlidesHero />
      <ProductNavigation />
      <BoseBanner />
      <FeaturedLogos />
      <WhatWeDo />
      <OurProducts />
      <ShippingTrainingSupport />
      <OurClients />
      <ProjectAndPartners />
      <LazyLoadComponent>
        <CustomerReviews />
      </LazyLoadComponent>
      <CustomerVideos />
      {/*<BoseVentures/>*/}
      <FastestGrowingCompany />
      {latestBlogData && (
        <LatestBlogs component={<LatestBlogCarousel data={latestBlogData} />} />
      )}
      <PublishedResearch />
      <GetInTouch />
      <BlackFridayPromoPopup />
    </Layout>
  );
};

export default Index;

export async function getStaticProps() {
  const blogPostData = await blogData();

  return {
    props: {
      blogPostData,
    },
  };
}
